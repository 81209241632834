import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"

const Copyright = () => {
  return (
    <Layout>
      <Seo title="Copyright" />
      <h1>Copyright</h1>
      <p>
        Unless otherwise noted, all content on cookwithheather.com is copyrighted by 
        Heather Jones, with all rights reserved. Under copyright law, this material 
        cannot be reproduced in any form without the prior written permission of Heather 
        Jones.</p>
    </Layout>
  )
}

export default Copyright;
